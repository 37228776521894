@import "../../assets/sass/_variables";

.dropZone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 4px;
  border-color: #dddddd;
  border-style: solid;
  background-color: #fcfcfc;
  color: #818a91;
  outline: none;
  transition: border 0.24s ease-in-out;
  text-align: center;
}

.dropZoneDisabled {
  display: none;
}

.fileBlockContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.fileBlock {
  display: flex;
}

.fileIcon {
  margin-right: 20px;
}

.fileSize {
  color: $grey;
}

.description {
  color: $grey;
}

.fileName {
  color: $bodyFontColor;
  text-decoration: none;
}
