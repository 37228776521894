@import "../../assets/sass/_variables";

.stepIndicator {
  height: 24px;
  width: 24px;
  background-color: $blue;
  color: #fff;
  border-radius: 50%;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
}

.button {
  margin-bottom: 15px;
}

.headerContainer {
  display: flex;
  font-size: 18px;
  align-items: center;
  h2 {
    margin: 0 0 0 10px;
  }
}

.form {
  .row {
    margin-bottom: 20px !important;
  }
}

.verifyForm {
  margin-top: 25px;
  .row {
    margin-bottom: 20px !important;
  }
  .title > div {
    padding-left: 0;
  }
}
