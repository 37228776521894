.footer-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

footer {
  padding: 4em 0 5em 0;
  margin-top: 2em;
  color: $grey;
  background: $greyHover;
  border-top: 1px solid $lightGrey;

  a {
    color: $grey;
    text-decoration: none;
  }

  p {
    margin: 0;
  }

  .footer-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 2em;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .footer-title {
    color: #000;
    font-size: 26px;
    margin-bottom: 0.8em;
  }

  .primary {
    max-width: 400px;
  }

  .avainlippu {
    width: 30px;
    margin-right: 2em;
  }
}
