// Required bootstrap sass files
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
// Optional bootstrap sass files
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/forms/input-group";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/containers";

// redux toastr
@import "~react-redux-toastr/src/styles/index";

@import url("https://fonts.googleapis.com/css?family=Mukta&display=swap");

// config
@import "./assets/sass/_variables";

// import dom elements
@import "./assets/sass/components/header";
@import "./assets/sass/components/footer";
@import "./assets/sass/components/btn";

// page styles
@import "./assets/sass/pages/userpage";

// general styles
@import "./assets/sass/forms";

// actual components
@import "./assets/sass/components/directory";
@import "./assets/sass/components/searchbox";
@import "./assets/sass/components/survey-find-user";

// print stylesheet
@import "./assets/sass/print";

// common styles
@import "./assets/sass/common";
