h2.subheader {
  margin-top: 0;
  font-size: 22px;
}

.userContainer {
  display: flex;
  justify-content: space-between;
  width: 30em;
  margin-bottom: 20px;
}

.userList {
  padding: 0;
  margin-top: 20px;
}

.options {
  margin-top: 0.5em;

  label {
    margin-right: 1em;
  }
}

.submitButtons {
  margin-top: 0.5em;

  button,
  a {
    margin-right: 1em;
  }
}
