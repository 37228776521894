@import "../../assets/sass/variables";

.formSubmit {
  margin-top: 1em;

  button:first-child {
    margin-left: 0;
  }
}

.progressBar {
  margin-top: 30px;
  height: 27px;
  border-radius: 18px;
  background: $grey;

  span {
    transition: width 0.5s ease-in-out;
    width: 0%;
    border-radius: 18px;
    display: block;
    height: 100%;
    background: $blue;
  }
}
