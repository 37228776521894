@import "../../assets/sass/_variables";

.registerForm {
  max-width: 700px;
  margin-bottom: 60px;
}

.registerIntro {
  color: $grey;
  margin-bottom: 40px;
}
