.finbb-btn {
  display: flex;
  font-size: inherit;
  align-items: center;
  padding: 1em 34px;
  text-decoration: none;
  background: $blue;
  line-height: 100%;
  color: #fff;
  border: 0;
  outline: 0;
  margin: 0;
  cursor: pointer;

  &.transparent {
    background: transparent;
    color: #ccc;
  }
}

.button {
  border-radius: 18px;
  background-color: $darkBlue;
  color: white;
  border: 0;
  padding: 5px 20px;
  outline: 0 !important;
  text-decoration: none;

  &:hover {
    background-color: lighten($darkBlue, 8%);
  }

  &:active {
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.05);
  }

  &.button-secondary {
    background-color: #dddddd;
    color: black;

    &:hover {
      background-color: darken(#dddddd, 5%);
    }
  }

  &.button-block {
    display: block;
    width: 100%;
  }

  &.button-alert {
    background-color: $red;
  }
}

.button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 10px;
  }

  &.flex-start {
    justify-content: flex-start;
  }
}
