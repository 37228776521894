.infoText {
  padding-bottom: 1em;
  text-align: center;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
