.userHeader {
  h1 {
    margin-top: 0;
    font-size: 26px;
    margin-bottom: 20px;
  }
}

.logo {
  margin: 100px 0 90px 0;
  text-align: center;
}
